// Keep in sync with LoanApplicationStatus in backend
export const LoanApplicationStatus = {
    pending: 'pending',
    preQualified: 'preQualified',
    preQualificationAccepted: 'preQualificationAccepted',
    incomeVerification: 'incomeVerification',
    incomeVerified: 'incomeVerified',
    floodInsuranceVerification: 'floodInsuranceVerification',
    floodInsuranceVerified: 'floodInsuranceVerified',
    offered: 'offered',
    accepted: 'accepted',
    awaitingSuccessfulNotarizations: 'awaitingSuccessfulNotarizations',
    withdrawn: 'withdrawn',
    declined: 'declined',
    approved: 'approved',
    denied: 'denied',
    trialDenialDm: 'trialDenialDm',
    deniedIndividualCanAddCoApplicant: 'deniedIndividualCanAddCoApplicant',
    humanInvestigate: 'humanInvestigate',
    ineligible: 'ineligible',
    incomplete: 'incomplete',
    duplicate: 'duplicate',
    mobileNotary: 'mobileNotary', // application is in a county that requires RIN
} as const

// Allows for enum-like typing of LoanApplicationStatus object above
export type LoanApplicationStatus = keyof typeof LoanApplicationStatus

// Keep in sync with NextHomeApplicationAction in aven_backend/src/controller/originationReturnController.ts
export const NextHomeApplicationAction = {
    // Applicant should be directed to the Thanks page
    THANKS: 'thanks',
    // Applicant should be redirected to page to add co applicant (because indiv denied on DTI alone)
    OFFER_ADD_COAPPLICANT: 'offerAddCoApplicant',
    // Applicant should view the pre-qual page
    PRE_QUALIFICATION: 'preQualification',
    // Applicant should view the hmda questionnaire
    HMDA: 'hmda',
    // Applicant should view the property verification page
    PROPERTY_VERIFICATION: 'propertyVerification',
    // Applicant should view the appropriate income verification page
    INCOME_VERIFICATION_TAX_RETURN: 'incomeVerificationTaxReturn',
    INCOME_VERIFICATION_BANK_STATEMENT: 'incomeVerificationBankStatement',
    INCOME_VERIFICATION_PAY_STUB: 'incomeVerificationPayStub',
    // Applicant should view the offer preview page
    OFFER_PREVIEW: 'offerPreview',
    // Applicant should view the appropriate flood insurance verification page
    FLOOD_INSURANCE_VERIFICATION: 'floodInsuranceVerification',
    // Applicant should be redirected to either coOwner page OR trust page
    // i.e. whatever they would normally see after accepting an offer
    POST_ACCEPT: 'postAccept',
    // Applicant should be directed to the notary product
    NOTARIZATION: 'notarization',
    // Applicant is already a customer so they should view the Welcome page
    WELCOME: 'welcome',
    DENIED: 'denied',
    PENDING: 'pending',
    REVIEW: 'review',
}
